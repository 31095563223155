import React from 'react';
import './styles/games.scss';
import Layout from '../components/layout';

export default () => (
	<Layout>
		<main>
			<article className='games'>
				<h3>
					I would love for you all to try out some of these games I've created below!
				</h3>
				<div>
					<h1>backronyms</h1>
					<h2>the game of making something from nothing</h2>
				</div>
				<div>
					<h1>skull</h1>
					<h2>one of my favourite party games, reimagined for online multiplayer</h2>
				</div>
				<hr className='yellow' />
				<p>
					Throughout my life, games in all shapes and forms have played an integral part in
					influencing my relationships, shaping the way I think, and even affecting my choice in career path.
					There's nothing better to liven up a timid room than introducing a casual party game to spark
					discussion and laugher; or how one can intrinsically learn about social roles -
					in real-life setting - by playing a massively multiplayer online role playing game - in a virtual setting.
					<br /><br />
					Though the context in which I consume games is always changing, the format
					remains as an important constant in my life. So it doesn't surprise me today that I continue
					to incorporate elements of gaming into my professional career as a web developer.
					<br /><br />
					Anyhow - I've always been excited at the idea of making online games, but was always turned off by the amount
					of manpower required in making one (graphics, massive development; not to mention everything from
					the business-side). I spent many-a-year learning development (originally iOS development with Objective-C)
					and developing my first mobile game, Super Sumo Sushi - which was a hit in my heart,
					but not a hit on the charts. 🙂 I love the idea of merging rapid development
					from the world of web into the world of games, so that I can scratch my itch to make more games
					without having to sacrifice the time needed for each one.
					<br /><br />
				</p>
			</article>
		</main>
	</Layout>
)
